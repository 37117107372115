<ng-container *ngIf="item; then hasItem; else hasNoItem"> </ng-container>

<ng-template #hasItem>
  <div class="clip-tile" [class.clip-tile--added]="isAddedToMyStack" [class.clip-tile--no-click]="!canAddToStack">
    <!-- includesmoderation ui -->
    <fs-thumbnail-poster
      [item]="item"
      [isModerated]="isModerated"
      [canModerate]="canModerate"
      [currentUserId]="currentUserId"
      (click)="openModal($event)"
      class="clip-tile__image"
    ></fs-thumbnail-poster>
    <!-- <div
      class="clip-tile__image"
      [style.background-image]="'url(' + item.poster + ')'"
      (click)="openModal($event)"
      ></div> -->
      <!-- <div class="clip-tile__duration">
        {{ item.duration | duration }}
      </div> -->
    <div class="clip-tile__content">
      <h3 class="clip-tile__content__title" (click)="openModal($event)">
        {{ (item.title || item.id) | sentenceCase }} 
      </h3>
      <fs-uploader-stats
        [date]="item.created | date"
        [userId]="item.userId"
        [showAvatar]="true"
        [showStats]="true"
        [stacks]="item.numStacks"
        [indicateNotHls]="!(item?.hlsSrc?.length > 0)"
        [indicateNeedsApproved]="isModerated && !item.isApproved"
        [views]="-1"
      ></fs-uploader-stats>
 
      <div *ngIf="canAddToStack" class="clip-tile__content__quick-action">
        <ion-button
          color="medium"
          style="height: 48px; width: 48px"
          [fill]="isAddedToMyStack ? 'solid' : 'outline'"
          (click)="toggleClipInMyStacks(item, $event)"
        >
          <div style="font-size: 30px; display: flex">
            <ng-container *ngIf="isAddedToMyStack">
              <ion-icon name="checkmark-outline"></ion-icon>
            </ng-container>
            <ng-container *ngIf="!isAddedToMyStack">
              <ion-icon name="add-outline"></ion-icon>
            </ng-container>
          </div>
        </ion-button>
      </div>
      <div class="clip-tile__content__action">
        <fs-ellipsis-button (click)="openActions($event)"></fs-ellipsis-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #hasNoItem>
  <div class="clip-tile">{{ 'LISTS.NO_ITEM' | translate }}</div>
</ng-template>
