import { Component, OnInit, Input } from '@angular/core';
import { ProjectMemberService } from '@members/shared/services';
import { Observable } from 'rxjs';
import { DEFAULT_USER_AVATAR } from '@shared/models/user.model';

@Component({
  selector: 'fs-uploader-stats',
  templateUrl: './uploader-stats.component.html',
  styleUrls: ['./uploader-stats.component.scss'],
})
export class UploaderStatsComponent implements OnInit {
  @Input() date: string;
  @Input() views: number;
  @Input() likes: number;
  @Input() shares: number;
  @Input() members: number;
  @Input() stacks: number;
  @Input() userId: string;
  @Input() showStats = true;
  @Input() showUploader = true;
  @Input() showAvatar = false;
  @Input() indicateNotHls = false;
  @Input() indicateNeedsApproved = false;
  @Input() isCollaborative = 0;

  @Input() viewsTitle = 'Views';
  @Input() stacksTitle = 'Stacks';

  /** handle more items to load (+) */
  @Input() moreViews: boolean = false;
  @Input() moreStacks: boolean = false;

  avatarUrl: string;
  username$: Observable<string>;
  avatarFallback = DEFAULT_USER_AVATAR;

  constructor(private memberService: ProjectMemberService) {}

  async ngOnInit() {
    if (this.userId) {
      this.username$ = this.memberService.getPublicUsername(this.userId);

      if (this.showAvatar) {
        this.avatarUrl = this.memberService.getUserAvatar(this.userId);
      }
    }
  }
}
